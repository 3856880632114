<template>
  <div class="hotelDetails">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>分类详情</el-breadcrumb-item>
    </el-breadcrumb>
    <el-form class="demo-form-inline" status-icon ref="ruleForm">
      <el-form-item label="分类id:" prop="userName">
        <span>{{ hotelDetails.id }}</span>
      </el-form-item>
      <el-form-item label="上级分类id:" prop="userName">
        <span>{{ hotelDetails.fatherId }}</span>
      </el-form-item>
      <el-form-item label="上级分类名称:" prop="userName">
        <span>{{ hotelDetails.fatherName }}</span>
      </el-form-item>
      <el-form-item label="分类图片:" prop="userName">
        <img :src="hotelDetails.cateImg" />
      </el-form-item>
    </el-form>
    <el-button type="primary" @click="$router.go(-1)">返回</el-button>
  </div>
</template>
<script>
import { hotelDetails } from "../../api/hotel";
export default {
  name: "HotelDetails",
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      hotelDetails: {},
    };
  },
  created() {
    this.getHotelDetails();
  },
  methods: {
    async getHotelDetails() {
      const { data } = await hotelDetails({
        cateId: this.id,
      });
      console.log(data.data[0]);
      this.hotelDetails = data.data[0];
    },
  },
};
</script>
<style lang="less" scoped>
.hotelDetails {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
  .el-form {
    margin-top: 50px;
    .el-form-item {
      img {
        width: 100px;
        height: 100px;
      }
    }
  }
}
</style>